import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "HS and HTS Codes",
  "description": "HS and HTS Codes are both product classification codes used for customs and tax purposes.",
  "author": "Ally Cheng",
  "categories": ["shipping-packaging"],
  "date": null,
  "featured": false,
  "tags": ["shipping-packaging"],
  "relatedArticles": []
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "486px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "54.826254826254825%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMCBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2I2SljCP/8QAGhABAAEFAAAAAAAAAAAAAAAAAQMAAhAREv/aAAgBAQABBQJk0soVY9Gf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAERIDH/2gAIAQEABj8CkZxlx//EABoQAQABBQAAAAAAAAAAAAAAAAEAESAxccH/2gAIAQEAAT8hJ6jROIEwps//2gAMAwEAAgADAAAAEPPv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARUf/aAAgBAgEBPxDTkt//xAAaEAEBAQEAAwAAAAAAAAAAAAABESEAIDGh/9oACAEBAAE/EHQwfeBwi1bDQSy8uALlJ88P/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2e7b3166c76f31f3f5008162f2724824/652f5/hs-code.webp 259w", "/static/2e7b3166c76f31f3f5008162f2724824/aba12/hs-code.webp 486w"],
            "sizes": "(max-width: 486px) 100vw, 486px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/2e7b3166c76f31f3f5008162f2724824/8356d/hs-code.jpg 259w", "/static/2e7b3166c76f31f3f5008162f2724824/d7db9/hs-code.jpg 486w"],
            "sizes": "(max-width: 486px) 100vw, 486px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/2e7b3166c76f31f3f5008162f2724824/d7db9/hs-code.jpg",
            "alt": "hs code",
            "title": "hs code",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "what-are-hs-and-hts-codes"
    }}>{`What are HS and HTS Codes?`}</h2>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`HS (Harmonized System) code`}</strong>{` is the international product classification code used during the shipping of products from one country to another.`}</p>
      <p parentName="blockquote"><strong parentName="p">{`HTS (Harmonized Tariff Schedule) code`}</strong>{` is a specific classification code system used by the United States (US) for the shipping of products between the US to different countries.`}</p>
    </blockquote>
    <p>{`The `}<strong parentName="p">{`HS code`}</strong>{` `}<strong parentName="p">{`is a classification system used to classify globally traded products`}</strong>{`. Customs around the world determine tariffs and tax rates on commodities based on their different HS codes. Different supervising processes, tariff collection, and VAT values are also decided based on the HS codes.`}</p>
    <p>{`The HS code system is made up of an internationally-accepted core 6-digit code, which separates commodities into 22 different categories.`}</p>
    <p>{`Different countries then add their own extended numbers to further distinguish certain categories of products specific to their country. For example, in the UK, across Europe, and China, a 10-digit HS code is used. On the other hand, countries like India, use an 8-digit HS code. This extended code is also known as the custom code.`}</p>
    <p>{`Despite the different terms, the HS code and custom code are often used interchangeably during international trade or import-export operations.`}</p>
    <p>{`Meanwhile, the `}<strong parentName="p">{`HTS code is a 10-digit classification system used in the United States`}</strong>{` for import tariffs. The HTS code is established on the basis of HS, in which the first six digits are consistent with the internationally accepted 6-digit code, and the last four digits are the detailed codes for different commodities. The code is a comprehensive customs code for goods formulated by the World Customs Organization in January 1989.`}</p>
    <h2 {...{
      "id": "uses-of-hs-code"
    }}>{`Uses of HS Code`}</h2>
    <p>{`The role of HS code:`}</p>
    <p>{`1`}{`.`}{` Reflect product information`}</p>
    <p>{`2`}{`.`}{` It is convenient for the customs to supervise the goods`}</p>
    <p>{`Customs also need to use HS codes to carry out relevant supervision and statistics on imported and exported commodities, so that they can also conduct real statistics on commodities according to different codes, they can and can implement relevant tariff collection, etc.`}</p>
    <p>{`3`}{`.`}{` Commodity inspection`}</p>
    <p>{`Because after passing such HS code, it is also possible to truly conduct relevant inspections on the commodities that need to be inspected.`}</p>
    <p>{`4`}{`.`}{` Related taxes`}</p>
    <p>{`The tax bureau can also collect taxes related to commodities based on such HS codes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      